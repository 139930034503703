import $ from 'jquery';
import 'select2';

import SubmissionListTable from "./table";
import EditSubmissionModal from "./edit_modal";
import {reportUnexpectedAjaxError} from "../../utils";
import {SubmissionStatus} from "./constants";
import Swal from "sweetalert2";

$(document).ready(function() {

    if ($('.template-order-submissions').length === 0) {
        return;
    }

    // Setup table
    const table = new SubmissionListTable(
        '#submissions-table',
        $('#submissionListURL').val()
    );
    table.on('dataLoaded', ()=>{
        setStatusCounts(table.statusCounts);
    });
    table.on(SubmissionListTable.EDIT_ROW, submission => {
        editSubmissionModal.open(submission);
    });
    table.on(SubmissionListTable.ACCEPT_ROW, acceptSubmission);
    table.on(SubmissionListTable.REJECT_ROW, confirmRejectSubmission);

    // Setup edit modal
    const editSubmissionURLTemplate = $('#editSubmissionURL').val();
    const editSubmissionModal = new EditSubmissionModal(
        $('#editSubmissionModalTemplate').html(),
        editSubmissionURLTemplate
    );
    editSubmissionModal.onDataSaved(()=>{table.reload();});

    // Setup search and filters
    table.bindSearchInput($('#searchFilter'));
    table.bindFilterButtons($('.btn-filter'));

    function setStatusCounts(counts) {
        $('.needs-review-count').text(`(${counts.needs_review})`);
        $('.accepted-count').text(`(${counts.accepted})`);
        $('.rejected-count').text(`(${counts.rejected})`);
    }

    function patchSubmission(submissionToken, data) {
        console.log('patching submission', submissionToken, data);

        $.ajax({
            method: 'patch',
            url: editSubmissionURLTemplate.replaceAll('$submission_token$', submissionToken),
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data)
        }).done(() => {
            console.debug('Successfully patched submission', submissionToken);
            table.reload();
        }).fail(error => {
            console.error('Failed to patch submission', submissionToken, error);
            reportUnexpectedAjaxError(error, 'updating the submission');
        });
    }

    function acceptSubmission(submission) {
        // accept the submission and update its employee
        const data = {
            status: SubmissionStatus.Accepted,
            update_employee: true
        };
        patchSubmission(submission.token, data);
    }

    function rejectSubmission(submission) {
        const data = {
            status: SubmissionStatus.Rejected
        };
        patchSubmission(submission.token, data);
    }

    function confirmRejectSubmission(submission) {
        Swal.fire({
            title: 'Reject Submission?',
            text: 'You will be unable to undo this operation',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Reject',
            confirmButtonColor: 'red',
        }).then(result => {
            if (result.isConfirmed) {
                rejectSubmission(submission);
            }
        });
    }
});