import $ from 'jquery';
import Cookies from 'js-cookie';


// Automatically add the X-CSRFToken header to any unsafe ajax requests
$.ajaxSetup({
    beforeSend: (request, settings) => {
        if (!settings.crossDomain && ['POST', 'PATCH', 'PUT', 'DELETE'].includes(settings.type)) {
            request.setRequestHeader('X-CSRFToken', Cookies.get('csrftoken'));
        }
    }
});

$(document).ready(()=>{
   $('[data-toggle="tooltip"]:not(.tooltip-manual)').tooltip();

    $('.alert-dismissible .btn-close').click(function(){
        $(this).parent().alert('close');
    });

    $.validator.setDefaults({
        ///red border on invalid input, removed when valid////
        errorClass: 'help-block',
        highlight: function(element) {
            $(element).closest('.form-group-red').addClass('error');
        },
        unhighlight: function(element) {
            $(element).closest('.form-group-red').removeClass('error');
        },
    });

    ////validation for 8+ characters, one number or special character////
    $.validator.addMethod('strongPassword', function(value, element)    {
        return this.optional(element)
            || value.length >= 8
            // check for special characters (non-word characters)
            && /\W/.test(value)
            // check for letters
            && /[A-Za-z]/.test(value)
            // check for numbers
            && /\d/.test(value)
    },  'Must be 8 or more characters and contain at least 1 number and special character');

    // Copy Coupon Code script
    $('body').on('click','#copy-button',function(){

            // Get Coupon Code from Input Field
            let copyText = document.getElementById('myInput');

            // Select the text field //
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            // Copy the text inside the text field to the clipboard
            navigator.clipboard.writeText(copyText.value);
            document.execCommand('copy');

            // Alert or display "copied!" message to user
            $('.copy-success').removeClass('d-none');

            // After showing "copied!" - hide again after 2500 ms
            window.getSelection().removeAllRanges();
            setTimeout(function () {
                $('.copy-success').addClass('d-none');
            }, 2500);
        }
    );

});


