import $ from 'jquery';

import {reportUnexpectedAjaxError} from "./utils";

/*
Manages the life cycle of a multi page form displayed as a modal. The modal is recreated from
scratch (via the passed template) each time open() is called.

Once open, the user follows a "wizard like" process. They will start on the first page, and
only be allowed to continue to the next once all data has been validated. On completion of the
final page, the form data is submitted via ajax. Any errors returned by the endpoint are then marked
on the form and the user goes through the process again (fixing errors). When the form data has
successfully been submitted the modal closes and any callbacks passed to onDataSaved() are run.

The passed template should:
- be a bootstrap modal stored as a string
- contain one or more page elements with a 'page' class and a single form
- contain a button with the class 'submit-page' that's shown on every page
*/
export class MultiPageFormModal {
    constructor(template, submitURL, submitMethod = 'POST') {
        this.template = template;
        this.submitURL = submitURL;
        this.submitMethod = submitMethod;

        this.submitButtonText = 'Submit';
        this.nextPageButtonText = 'Next';

        this.$modal = null;

        this._pages = [];
        this._currentPageIndex = null;
        this._submitCallbacks = [];
    }

    open(initialData = {}) {
        // Ensure we are starting with a blank form
        this.close();

        this.$modal = $(this.template);
        $(document.body).append(this.$modal);

        this.setData(initialData);

        // setup pages
        this._pages = this.$modal.find('.page').addClass('d-none').toArray();
        this.$modal.find('.submit-error').addClass('d-none');
        this.setPage(0);

        // bind events
        this.$modal.find('.submit-page').click(this.submitCurrentPage.bind(this));
        this.$modal.on('change keyup', 'input,select,textarea', event => {
            // Clear any server side errors
            event.target.setCustomValidity('');
            this.checkValidity();
        });

        this.$modal.modal('show');
    }

    setData(data) {
        this.$modal.find("[name]").each((_, el) => {
            if (data.hasOwnProperty(el.name)) {
                el.value = data[el.name] || null;
            }
        });
    }

    // Delete the current modal element and reset all state
    close() {
        if (!this.$modal) {
            return;
        }

        this.$modal.modal('hide');
        this.$modal.remove();
        this._pages = [];
        this._currentPageIndex = null;
    }

    onDataSaved(func) {
        this._submitCallbacks.push(func);
    }

    getCurrentPage() {
        return this._pages[this._currentPageIndex];
    }

    onLastPage() {
        return this._currentPageIndex === this._pages.length - 1;
    }

    setPage(index) {
        if (this._currentPageIndex !== null) {
            // Hide the current page first
            this.getCurrentPage().classList.add('d-none');
        }

        this._currentPageIndex = index;
        const $currentPage = $(this.getCurrentPage());
        $currentPage.removeClass('d-none');

        this.$modal.find('.sub-header').text($currentPage.attr('data-title'));

        this.$modal.find('.submit-page').text(
            this.onLastPage() ? this.submitButtonText : this.nextPageButtonText
        );
    }

    submitCurrentPage() {
        const $form = $(this.getCurrentPage()).find('form');
        if (!$form[0].checkValidity()) {
            $form.addClass('was-validated');
            // do not continue until the form validates
            return;
        }

        if (this.onLastPage()) {
            this.save();
        } else {
            this.setPage(this._currentPageIndex + 1);
        }
    }

    checkValidity() {
        const allValid = this.$modal.find(':invalid, .is-invalid').length === 0;

        if (allValid) {
            // If all forms are valid, ensure the submit error is not shown
            this.$modal.find('.submit-error').addClass('d-none');
        }
    }

    goToFirstPageWithErrors() {
        for (let pageIndex = 0; pageIndex < this._pages.length; pageIndex++) {
            if ($(this._pages[pageIndex]).find(':invalid,.is-invalid').length) {
                this.setPage(pageIndex);
                return;
            }
        }
    }

    setFieldErrors(errors) {
        for (const [fieldName, errorList] of Object.entries(errors)) {
            const $field = this.$modal.find(`[name="${fieldName}"]`);
            if ($field.length === 0) {
                continue;
            }

            let $fieldProxy = $field;
            if ($field.hasClass('select2-hidden-accessible')) {
                $fieldProxy = $field.next('.select2');
            }

            const errorMsg = errorList.join(', ');
            let $feedback = $fieldProxy.next();

            // Create the invalid feedback element for the field if it does not exist
            if (!$feedback.length || !$feedback.hasClass('invalid-feedback')) {
                $feedback = $('<div class="invalid-feedback"></div>');
                $fieldProxy.after($feedback);
            }

            $feedback.text(errorMsg);
            $field[0].setCustomValidity(errorMsg);
        }

        this.$modal.find('form').addClass('was-validated');
        this.$modal.find('.submit-error').removeClass('d-none');
        this.goToFirstPageWithErrors();
    }

    // convert form data into a JSON serializable object
    getData() {
        const data = {};

        this.$modal.find('form').serializeArray().forEach(field => {
            data[field.name] = field.value;
        });

        // checkbox fields are buggy with serializeArray - either omitted, or present with an empty value
        // to fix this, look for any checkbox fields on the form and fix the value to 0/1
        this.$modal.find('form input[type="checkbox"]').each(function() {
            const checkbox = $(this);
            console.log(checkbox);
            data[checkbox.attr('name')] = checkbox.prop('checked');
        })

        return data;
    }

    save() {
        const payload = this.getData();

        console.debug(`${this.submitMethod} data to ${this.submitURL}`, payload);

        $.ajax({
            url: this.submitURL,
            method: this.submitMethod,
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(payload)
        }).done(()=>{
            console.debug('Successfully submitted', payload);
            this.$modal.modal('hide');
            this._submitCallbacks.forEach(func => {
                func(payload);
            });
        }).fail(error => {
            console.error('Failed to submit', error);

            if (error.status === 400) {
                console.info('Failed to submit, invalid form data', error);
                // Set the server side errors on the invalid fields
                this.setFieldErrors(error.responseJSON);
            } else {
                console.error('Unexpected error submitting', error);
                reportUnexpectedAjaxError(error, 'saving the form');
            }
        });
    }
}