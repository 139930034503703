import $ from "jquery";
import RemoteTable from "../../remote_table";
import {SubmissionStatus} from "./constants";

export default class SubmissionListTable extends RemoteTable {
    constructor(selector, listURL) {
        super(selector, listURL, _submissionListColumns);
        this.filterParams.status = SubmissionStatus.PendingReview;
        this.statusCounts = {};
    }

    _prepareRemoteData(url, params, resp) {
        this.statusCounts = resp.counts;
        return resp.submissions;
    }
}
SubmissionListTable.EDIT_ROW = 'edit-row';
SubmissionListTable.ACCEPT_ROW = 'accept-row';
SubmissionListTable.REJECT_ROW = 'reject-row';


function _getRowPopupContent(e, cell) {
    const data = cell.getData();
    const notesOrNA = data.status_notes || 'N/A';

    const $popup = $(`
        <div>
            <strong>Email</strong>
            <div class="mb-2">${data.email}</div>
            
            <strong>Status Notes</strong>
            <div class="mb-2">${notesOrNA}</div>
            
            <strong>Address</strong>
            <div class="addr1">${data.address1}</div>
            <div>${data.city}, ${data.state}</div>
            <div class="mb-2">${data.zipcode}</div>
            
            <strong>Shoe Type / Size</strong>
            <div class="mb-2">${data.shoe_type} / ${data.shoe_size}</div>
        </div>
    `);

    if (data.address2) {
        $popup.find('.addr1').after(`<div>${data.address2}</div>`);
    }

    return $popup[0];
}

const _submissionListColumns = [
    {
        title: 'Date',
        field: 'date_submitted',
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getRowPopupContent
    },
    {
        title: 'Site',
        field: 'site_name',
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getRowPopupContent
    },
    {
        title: 'Name',
        field: 'full_name',
        minWidth: 140,
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getRowPopupContent
    },
    {
        title: 'Verified',
        field: 'employee',
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getRowPopupContent,
        formatter: cell => {
            return cell.getData().employee ? 'Yes' : 'No';
        }
    },
    {
        title: '&nbsp;',
        field: 'actions',
        resizable: false,
        headerSort: false,
        minWidth: 155,
        formatter: cell => {
            if (cell.getData().mutable) {
                return `
                    <div class="d-flex align-items-center justify-content-center gap-3">
                        <button class="desktop-only btn btn-green color-green ${SubmissionListTable.ACCEPT_ROW}" role="button">Accept</button>
                        <i class="tablet-down bi bi-check-square-fill fs-4 color-green ${SubmissionListTable.ACCEPT_ROW}" role="button"></i>
                        <i class="bi bi-x-square-fill fs-4 red-x ${SubmissionListTable.REJECT_ROW}" role="button"></i>
                        <i class="bi bi-pencil-square fs-5 ${SubmissionListTable.EDIT_ROW}" role="button"></i>
                    </div>
                `;
            }
        },
        cellClick: (event, cell) => {
            event.preventDefault();
            event.stopPropagation();

            const $target = $(event.target);
            const tableActions = [
                SubmissionListTable.EDIT_ROW,
                SubmissionListTable.ACCEPT_ROW,
                SubmissionListTable.REJECT_ROW
            ];

            for (const action of tableActions) {
                if ($target.hasClass(action) ||
                    $target.parents(`.${action}`).length)
                {
                    cell.getTable().dispatchEvent(action, cell.getData());
                    break;
                }
            }
        }
    }
];