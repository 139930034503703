import {MultiPageFormModal} from "../../forms";
import {GooglePlaceDetails, makeAddressAutocomplete} from "../../places";

export default class AddEmployeeModal extends MultiPageFormModal {
    setData(data) {
        super.setData(data);

        // Set up the address autocomplete
        const $place_autocomplete = this.$modal.find('[name="place_autocomplete"]');
        const addressAutoComplete = makeAddressAutocomplete($place_autocomplete[0]);

        addressAutoComplete.addListener('place_changed', () => {
            let place;
            try {
                place = new GooglePlaceDetails(addressAutoComplete.getPlace());
            } catch (e) {
                // if place details fails to load, ignore
                return;
            }

            const address1 = place.getStreetAddress();
            if (address1) {
                this.$modal.find('[name="address1"]').val(address1);
            }

            const city = place.getCity();
            if (city) {
                this.$modal.find('[name="city"]').val(city);
            }
            const state = place.getStateCode();
            if (state) {
                this.$modal.find('[name="state"]').val(state);
            }
            const zipcode = place.getZipCode();
            if (zipcode) {
                this.$modal.find('[name="zipcode"]').val(zipcode);
            }

            const country_code = place.getCountryCode();
            if (country_code) {
                this.$modal.find('[name="country_code"]').val(country_code);
            }

            // Focus the second address line (which is not autofilled)
            this.$modal.find('[name="address2"]').focus();
        });
    }
}