import {MultiPageFormModal} from "../../forms";
import {SubmissionStatus} from "./constants";


export default class EditSubmissionModal extends MultiPageFormModal {
    constructor(template, submitURLTemplate) {
        super(template, null);

        this.submitURLTemplate = submitURLTemplate;
        this.submitButtonText = 'Save';
    }

    getData() {
        const data = super.getData();

        // If the employee input does not have a selection, it is excluded from the data.
        // To prevent this behavior, add it with a null value if it's missing.
        if (!data.employee) {
            data.employee = null;
        }

        return data;
    }

    setData(submission) {
        // set the submission specific submit URL
        this.submitURL = this.submitURLTemplate.replaceAll('$submission_token$', submission.token);

        super.setData(submission);

        const employeesByToken = {};
        const $employee = this.$modal.find('select[name="employee"]');

        // Set the existing employee if one is already mapped
        if (submission.employee) {
            $employee.append(`
                <option value="${submission.employee.token}">${submission.employee.full_name}</option>
            `).val(submission.employee.token).change();
            employeesByToken[submission.employee.token] = submission.employee;
        }

        $employee.select2({
            theme: 'bootstrap-5',
            dropdownParent: $employee.parent(),
            placeholder: '',
            allowClear: true,
            ajax: {
                url: this.submitURL + 'employees/',
                dataType: 'json',
                delay: 250,
                cache: true,
                data: params => {
                    return {search: params.term};
                },
                processResults: data => {
                    const employees = [];

                    data.forEach(employee => {
                        let label = employee.full_name;
                        if (employee.email === submission.email) {
                            label += ' (email matches)';
                        } else if (employee.full_name === submission.full_name) {
                            label += ' (name matches)';
                        }
                        employees.push({
                            id: employee.token,
                            text: label
                        });
                        employeesByToken[employee.token] = employee;
                    });

                    return {results: employees};
                }
            }
        });

        // Determine whether the "update employee" checkbox is disabled whenever the selected employee is changed.
        // The checkbox is only enabled if the selected employees fields do not match the submissions.
        $employee.change(()=>{
            const employeeToken = $employee.val();

            const $updateEmployee = this.$modal.find('[name="update_employee"]');
            if (employeeToken && !employeesByToken[employeeToken].fields_match) {
                $updateEmployee.prop('disabled', false);
                $updateEmployee.prop('checked', true);
            } else {
                $updateEmployee.prop('disabled', true);
                $updateEmployee.prop('checked', false);
            }
        }).change();

        // Update the placeholder text of the employee select based on the selected status.
        const $status = this.$modal.find('[name="status"]');
        $status.change(() => {
            let employeePlaceholder = 'Select Employee';

            if ($status.val() === SubmissionStatus.Accepted) {
                // An employee will only be created for accepted orders
                employeePlaceholder = 'Automatically create';
            }

            $employee.data('select2').selection.placeholder.text = employeePlaceholder;
            $employee.change();
        }).change();
    }

}