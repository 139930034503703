import $ from 'jquery';
import {TabulatorFull} from "tabulator-tables";


const NON_FIELD_ERRORS = '__all__';
const EXCLUDED_COLUMNS = ['import_type'];
const PAGINATION_SIZE = 5;
const ImportState = {
    // The user is selecting the file.
    SelectingFile: 'selectingFile',
    // The file was parsed, but some rows are invalid. The table with per row errors is shown.
    InvalidRows: 'invalidRows',
    // The file was parsed and validated. The import preview is shown.
    FileValid: 'fileValid',
};

export default class ImportEmployeesModal {
    constructor($modal, importURL) {
        this.$modal = $modal;
        this.importURL = importURL;

        this._previewTable = null;
        this._state = ImportState.SelectingFile;

        this._callbacks = [];
        this._setupUI($modal);
    }

    open() {
        this.$modal.modal('show');
    }

    clear() {
        this.$modal.find('input[type=file]').val(null).removeClass('requesting').change();
        this._state = ImportState.SelectingFile;
        this._recalculateDisplayedElements();
    }

    onSuccessfulImport(callback) {
        this._callbacks.push(callback);
    }

    _recalculateDisplayedElements() {
        setDisplayed(
            this.$modal.find('.table-container'),
            this._state === ImportState.FileValid || this._state === ImportState.InvalidRows
        );
        setDisplayed(
            this.$modal.find('.invalid-rows-msg'),
            this._state === ImportState.InvalidRows
        );
        setDisplayed(
            this.$modal.find('.preview-msg'),
            this._state === ImportState.FileValid
        );

        const showPreviewBtn = this._state !== ImportState.FileValid;
        setDisplayed(this.$modal.find('.preview-btn'), showPreviewBtn);
        setDisplayed(this.$modal.find('.import-btn'), !showPreviewBtn);
    }

    _setTableData(rows, formatHTML = false) {
        if (this._previewTable) {
            // Recreate the table from scratch each time to avoid bugs in tabulators responsive module
            this._previewTable.destroy();
        }

        this._previewTable = new TabulatorFull(this.$modal.find('.table-container .rows-table')[0], {
            data: [],
            responsiveLayout: 'collapse',
            layout: 'fitDataStretch',
            // Don't show the pagination footer if it's not needed
            pagination: rows.length > PAGINATION_SIZE,
            paginationSize: PAGINATION_SIZE,
            // Infer columns automatically from resp data
            autoColumns: 'full',
            autoColumnsDefinitions: definitions => prepareTabulatorColumnDefinitions(
                definitions, formatHTML
            )
        });
        // Data must be set after the table is ready, otherwise the responsive plugin may not work
        this._previewTable.on('tableBuilt', () => {
            this._previewTable.setData(rows).then(() => {
               this._previewTable.redraw(true);
            });
        });

        // Expand/hide collapsed columns on row click
        this._previewTable.on('rowClick', (event, row) => {
            const $hiddenCols = $(row.getElement()).find('.tabulator-responsive-collapse');

            if ($hiddenCols.is(event.target) || $hiddenCols.has(event.target).length) {
                // Ignore clicks on the expanded part
                return;
            }

            $hiddenCols.toggleClass('open');
            row.getTable().rowManager.adjustTableSize();
        });
    }

    _setInvalidRowsResult(result) {
        // If there are unknown errors, show the message and set the reference number
        this.$modal.find('.reference-number').text(result.reference_number);
        setDisplayed(
            this.$modal.find('.unknown-errors-msg'),
            result.error_row_numbers > 0
        );

        // Prepare tabulator data by flattening error messages and combining invalid_rows with error_row_numbers
        const errorRows = [];
        result.invalid_rows.forEach(rowData => {
            errorRows.push(
                Object.assign({row_number: rowData.row_number}, rowData.error_messages)
            );
        });
        result.error_row_numbers.forEach(rowNum => {
            errorRows.push({row_number: rowNum, [NON_FIELD_ERRORS]: 'An unknown error occurred'});
        });

        this._setTableData(errorRows);

        this._state = ImportState.InvalidRows;
        this._recalculateDisplayedElements();
    }

    _setPreviewResult(result) {
        // Update the row counters
        this.$modal.find('.preview-msg .new').text(result.totals.new);
        this.$modal.find('.preview-msg .updated').text(result.totals.update);
        this.$modal.find('.preview-msg .skipped').text(result.totals.skip);

        this._setTableData(result.rows, true);

        this._state = ImportState.FileValid;
        this._recalculateDisplayedElements();
    }

    _ajaxIsRunning() {
        return this.$modal.hasClass('requesting');
    }

    _submitViaAJAX(dryRun) {
        if (this._ajaxIsRunning()) {
            throw Error('Request already in progress');
        }

        this._setSubmitError(null);

        const $form = this.$modal.find('form');
        $form.find('[name="dry_run"]').val(dryRun);

        this.$modal.addClass('requesting');
        const request = $.ajax({
            url: this.importURL,
            method: 'POST',
            data: new FormData($form[0]),
            contentType: false,
            processData: false
        });

        request.always(()=>{
            this.$modal.removeClass('requesting');
        });

        return request;
    }

    _onImportFinished(resp) {
        console.log('Employees successfully imported', resp);
        this.$modal.modal('hide');
        this.clear();

        this._callbacks.forEach(func => {
            func(resp);
        });
    }

    _setSubmitError(msg) {
        msg = msg || ''; // ensure blank string and not null or undefined
        setDisplayed(this.$modal.find('.submit-error').text(msg), msg);
    }

    _setupUI($modal) {
        const $file = $modal.find('input[type=file]');
        const $uploadText = $modal.find('.file-status');
        const $uploadBox = $modal.find('.upload-box');
        const $preview = $modal.find('.preview-btn');
        const $import = $modal.find('.import-btn');

        const defaultUploadText = $uploadText.text();

        $uploadBox.click(()=>{
            $file.click();
        });
        $file.change(()=>{
            if ($file[0].files.length !== 0) {
                $uploadText.text($file[0].files[0].name);
                $uploadBox.addClass('has-file');
                // Wait half a second before enabling submit to prevent it from accidentally being clicked.
                // At this point the native file dialog is closing
                setTimeout(()=>{
                    $preview.removeClass('disabled');
                }, 500);
            } else {
                $uploadText.text(defaultUploadText);
                $uploadBox.removeClass('has-file');
                $preview.addClass('disabled');
            }

            this._setSubmitError(null);
            this._state = ImportState.SelectingFile;
            this._recalculateDisplayedElements();
        });
        $preview.click(event => {
            event.preventDefault();

            if (this._ajaxIsRunning()) {
                return;
            }

            this._submitViaAJAX(true).done(resp => {
                this._setPreviewResult(resp);
            }).fail(err => {
                const fileError = extractFileErrorMsg(err.responseJSON);

                if (fileError) {
                    this._setSubmitError(fileError);
                } else if (isInvalidRowsResp(err.responseJSON)) {
                    this._setInvalidRowsResult(err.responseJSON);
                    this._setSubmitError('The file is invalid, please correct the errors above');
                } else {
                    // For generic errors clear the file and show a message.
                    console.error('preview load failed', err);
                    $file.val('').change();
                    this._setSubmitError('Unexpected error loading preview, please try again');
                }
            });
        });
        $import.click(event => {
            event.preventDefault();

            if (this._ajaxIsRunning()) {
                return;
            }

            this._submitViaAJAX(false).done(resp => {
                this._onImportFinished(resp);
            }).fail(err => {
                console.error('Failed to import employees', err);
                $file.val('').change();
                this._setSubmitError('Unexpected error importing file, please try again');
            });
        });
    }
}

function isInvalidRowsResp(resp) {
    try {
        return resp.hasOwnProperty('invalid_rows');
    } catch (e) {
        return false;
    }
}

function extractFileErrorMsg(resp) {
    try {
        // join the file error array
        return resp.file.join(', ');
    } catch (e) {
        return null;
    }
}

function setDisplayed($el, displayed) {
    if (displayed) {
        $el.removeClass('d-none');
    } else {
        $el.addClass('d-none');
    }
}

function prepareTabulatorColumnDefinitions(definitions, formatHTML) {
    // filter out excluded columns
    definitions = definitions.filter(col => !EXCLUDED_COLUMNS.includes(col.field));

    definitions.forEach(col => {
        if (col.field === 'row_number') {
            col.title = 'Row';
            col.formatter = cell => {
                const rowData = cell.getRow().getData();

                if (rowData.import_type) {
                    return `<div class="import-marker type-${rowData.import_type}">
                                            ${rowData.row_number}
                                        </div>`;
                }

                return rowData.row_number;
            };
        } else if (col.field === NON_FIELD_ERRORS) {
            col.title = 'Message';
        } else if (formatHTML) {
            col.formatter = cell => {
                const val = cell.getValue();
                // If a column val starts with < format as html
                if (typeof val === 'string' && val.startsWith('<')) {
                    return $('<span>').html(val)[0];
                }
                if (Array.isArray(val)) {
                    return val.join(', ');
                }
                return val;
            };
        }

        col.headerSort = false;
    });

    return definitions;
}