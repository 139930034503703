import $ from 'jquery';
import Swal from "sweetalert2";

import {reportUnexpectedAjaxError} from '../../utils';
import {MultiPageFormModal} from "../../forms";
import EmployeeListTable from "./table";
import ImportEmployeesModal from "./import";
import AddEmployeeModal from "./add_employee_modal";


$(document).ready(function () {

    // this code applies to the dashboard -> employee list page
    if($('.template-employee-list').length === 0) {
        return;
    }

    $(document).ready(function() { 
        $('.remove-modal').on('click', function() { 
            $('.modal-body, .modal-backdrop, .modal-content').hide(); 
        });
        $('.upload').on('click', function() { 
            $('.modal-body, .modal-backdrop, .modal-content').show(); 
        });
    });


    // Set up the table
    const employeeTable = new EmployeeListTable('#employee-table', $('#employeeListURL').val());
    employeeTable.on('cellClick', (event, cell) => {
        const field = cell.getColumn().getField();

        if (field === 'action_edit') {
            openUpdateEmployeeModal(cell.getData());
        } else if (field === 'action_delete') {
            confirmDeleteEmployee(cell.getData().token);
        } else if (field === 'active' && cell.getValue() !== cell.getOldValue()) {
            setEmployeesActiveStatus(cell.getData().token, cell.getValue());
        }
    });
    employeeTable.on('dataLoaded', () => {
        setEmployeeCounts(employeeTable.employeeCounts);
    });

    const importEmployeesModal = new ImportEmployeesModal(
        $('#importEmployeesModal'),
        $('#employeeImportURL').val()
    );
    importEmployeesModal.onSuccessfulImport(data => {
        // show confirmation message and then reload the table
        Swal.fire({
            title: 'Employees Imported',
            html: `<div>${data.new} Created</div><div>${data.update} Updated</div>`,
            icon: 'success'
        }).then(reloadTable);
    });
    $('#uploadEmployeesButton').click(()=>{
        importEmployeesModal.clear();
        importEmployeesModal.open();
    });

    // Set up the add employee modal
    const addEmployeeModal = new AddEmployeeModal(
        $('#addEmployeeModalTemplate').html(),
        $('#employeeCreateURL').val()
    );
    addEmployeeModal.onDataSaved(employee => {
        // show confirmation message and then reload the table
        Swal.fire({
            title: 'Employee Created',
            text: `Successfully added ${employee.first_name} ${employee.last_name}`,
            icon: 'success'
        }).then(reloadTable);
    });
    $('.add-employee-button').click(event=>{
        event.preventDefault();
        addEmployeeModal.open();
    });

    // Set up the edit employee modal
    const editEmployeeModal = new MultiPageFormModal(
        $('#editEmployeeModalTemplate').html(),
        null, // the URL is set when opened for a specific employee
        'PATCH'
    );
    editEmployeeModal.submitButtonText = 'Save';
    editEmployeeModal.onDataSaved(reloadTable);

    // Setup search and filters
    employeeTable.bindSearchInput($('#searchFilter'));
    employeeTable.bindFilterButtons($('.btn-filter'));

    function reloadTable() {
        employeeTable.reload();
    }

    function setEmployeeCounts(counts) {
        $('.all-employees-count').text(`(${counts.total})`);
        $('.active-employees-count').text(`(${counts.active})`);
        $('.inactive-employees-count').text(`(${counts.inactive})`);
    }

    function openUpdateEmployeeModal(employee) {
        editEmployeeModal.submitURL = buildEmployeeURL(employee.token);
        editEmployeeModal.open(employee);
        editEmployeeModal.$modal.find('#updateEmployeeSite').val(employee.site_token);
        editEmployeeModal.$modal.find('#updateEmployeeActive').prop('checked', employee.active);
    }

    function deleteEmployee(employeeToken) {
        console.debug('Deleting employee', employeeToken);
        $.ajax({
            url: buildEmployeeURL(employeeToken),
            method: 'DELETE'
        }).done(reloadTable).fail(err => {
            console.error('Failed to delete employee', employeeToken, err);
            reportUnexpectedAjaxError(err, 'deleting the employee');
        });
    }

    function confirmDeleteEmployee(employeeToken) {
        Swal.fire({
            title: 'Delete Employee?',
            text: 'You will be unable to undo this operation',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: 'red',
        }).then(result => {
            if (result.isConfirmed) {
                deleteEmployee(employeeToken);
            }
        });
    }

    function buildEmployeeURL(employeeToken) {
        return $('#employeeUpdateDeleteURL').val().replaceAll('$employee_token$', employeeToken);
    }

    function setEmployeesActiveStatus(employeeToken, active) {
        console.debug(`Setting employee ${employeeToken} active status to ${active}`);

        const payload = {active: active};

        $.ajax({
            url: buildEmployeeURL(employeeToken),
            method: 'PATCH',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(payload)
        }).done(reloadTable).fail(err => {
            console.error('Error changing employees active status', err);
            reportUnexpectedAjaxError(err, 'updating the employee');
        });
    }


    // employee list mobile filter pop up
    $(".employee-box").hide();
    $(".click-filter, .results-btn").click(function() {
        $(".btn-filter.active").show();
        $(".employee-box").toggle();
        $(".click-opacity, .toggle-up").toggleClass('active');
        $(".toggle-down").toggleClass('active');
    });

    // employee list hide/show filter button title
    //$(".active-mobile, .inactive-mobile").hide();     
    $("#click-active").click(function() {
        $(".active-mobile-show").show();
        $(".all-mobile-show, .inactive-mobile-show").hide();
    });

    $("#click-all").click(function()    {
        $(".all-mobile-show").show();
        $(".inactive-mobile-show, .active-mobile-show").hide();
        });

    $("#click-inactive").click(function()   {
        $(".inactive-mobile-show").show();
        $(".all-mobile-show, .active-mobile-show").hide();
        });
        
});
    




