import $ from 'jquery';

$(document).ready(()=>{

    if ($('.template-add-user').length === 0) {
        return;
    }

    const $userTypes = $('[name="user_type"]');
    const $sites = $('#sites');

    function updateSitesSection() {
        const userType = $userTypes.filter(':checked').val();

        if (userType === 'manager') {
            $sites.show();
        } else {
            $sites.hide();
        }
    }

    updateSitesSection();
    $userTypes.change(updateSitesSection);
});
