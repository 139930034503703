import $ from "jquery";
import 'jquery-validation';


$(document).ready(function () {

    // this code applies to the login/forgot password/recover password pages
    if ($('.template-login').length === 0) {
        return;
    }

    const validator = $('#login-form').validate({
        rules: {
            username: {
                required: true,
            },
            password: {
                required: true,
            }
        },
        messages: {
            username: {
                required: pgettext('field error', 'Please enter your username')
            },
            password: {
                required: pgettext('field error', 'Please enter your password')
            }
        }
    });

    //Check if fields are valid and add red border if not 
    $('body').on('click', '.login-btn', function () {
        const is_username_valid = validator.element('#username-field');
        const is_password_valid = validator.element('#password-field');

        if (!is_username_valid) {
            $('#username-field').addClass('error');
        } else {
            $('#username-field').removeClass('error');
        }
        if (!is_password_valid) {
            $('#password-field').addClass('error');
        } else {
            $('#password-field').removeClass('error');
        }
    });

    //Close dialog box error
    $('body').on('click', '.bi-x', function () {
        $('.alert-danger').addClass('d-none');
    });

    // toggle the password eye icon to show the password
    $('body').on('click', '.toggle-password', function () {
        $(this).toggleClass("bi-eye bi-eye-slash");
        const input = $($(this).attr("toggle"));
        if (input.attr("type") === "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    // get the timezone from the user's locale
    $("#timezone").val(Intl.DateTimeFormat().resolvedOptions().timeZone);

});