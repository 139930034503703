import $ from 'jquery';

$(document).ready(function () {

    if ($('.template-dashboard-main').length === 0) {
        return;

    }
    const windowWidth = $(window).width();

    $(".edit-form").hide();
    if (windowWidth >= 1024) {
        $(".box-fulfill-header").click(function () {
            $(".edit-form").toggle();
            $(".box-freq").toggle();
        });
    } else {
        $(".box-freq").hide();
        $(".box-fulfill-header").click(function () {
            $(".edit-form, .col-bottom-rt").toggle();
            $(".mob-opacity, .toggle-up").toggleClass('active');
            $(".toggle-down").toggleClass('active');
        });
    };


    ///shows logout pop up when clicking on logout link////
    $(".log-out-hide").hide();
    $(".logout-link, .logout-mob").click(function() {
       $(".log-out-hide").show();
       $(".nav-wrap, .menu").hide();
   });
    $(".logout-link, .logout-mob").click(function() {
       $(".click-blur, .mobile-filter").addClass('active');
   });
   
   ///removes log-out pop with cancel button///
       $(".cancel-click").click(function() {
         $(".log-out-hide").hide();
         $(".click-blur, .mobile-filter").removeClass('active');
         $(".nav-wrap, .menu").show();
      });
});







