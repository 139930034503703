import Swal from "sweetalert2";

export function debounce(duration, callback) {
    let timeout = null;

    function inner() {
        clearTimeout(timeout);
        timeout = setTimeout(callback, duration);
    }

    return inner;
}

export function applyURLQueryParameters(url, params) {
    if (Object.keys(params).length === 0) {
        return url;
    }

    if (!url.endsWith('?')) {
        url += '?';
    }

    return url + $.param(params);
}


export function reportUnexpectedAjaxError(response, userAction) {
    // TODO: Add more user friendly information and actions
    Swal.fire({
        title: 'Unexpected Error',
        text: `An error occurred while ${userAction}.`,
        icon: 'error',
        confirmButtonText: 'Reload the Page'
    }).then(() => {
        window.location.reload();
    });
}
