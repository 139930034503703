import $ from "jquery";
import {applyURLQueryParameters} from "../../utils";
import RemoteTable from "../../remote_table";

export default class EmployeeListTable extends RemoteTable {
    constructor(selector, listURL) {
        super(selector, listURL, _employeeListColumns);
        this.employeeCounts = {};
    }

    _prepareRemoteData(url, params, resp) {
        this.employeeCounts = resp.counts;
        return resp.employees;
    }
}

const _employeeListColumns = [
    {
        title: 'Site',
        field: 'site_name',
        minWidth: 140,
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getEmployeeCellPopupContent
    },
    {
        title: 'Employee Name',
        field: 'full_name',
        minWidth: 140,
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getEmployeeCellPopupContent
    },
    {
        title: 'Employee I.D.',
        field: 'employee_id',
        minWidth: 140,
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getEmployeeCellPopupContent
    },
    {
        title: 'Last Order Date',
        field: 'last_order_date',
        minWidth: 140,
        resizable: false,
        headerSort: true,
        headerSortStartingDir: 'desc',
        clickPopup: _getEmployeeCellPopupContent
    },
    {
        title: 'Status',
        field: 'active',
        formatter: 'toggle',
        formatterParams: {
            size: 20,
            onValue: 1,
            offValue: 0,
            onTruthy: true,
            onColor: 'green',
            offColor: 'grey',
            clickable: true
        }
    },
    {
        title: '&nbsp;',
        field: 'action_edit',
        formatter: () => {
            return '<i class="bi bi-pencil-square"></i>';
        },
        width: 40,
        hozAlign: 'right',
        resizable: false,
        headerSort: false,
    },
    {
        title: '&nbsp;',
        field: 'action_delete',
        formatter: () => {
            return '<i class="bi bi-trash color-red"></i>';
        },
        width: 40,
        hozAlign: 'left',
        resizable: false,
        headerSort: false,
    }
];

function _getEmployeeCellPopupContent(e, cell, onRendered){
    const data = cell.getData();

    const maybeNull = val => {return val || 'N/A';};

    const addr1 = data.address1;
    const addr2 = data.address2;
    let addressLines = '';
    if (addr1) {
        addressLines = addr1;
        if (addr2) {
            addressLines += `<br/>${addr2}`;
        }
    } else if (addr2) {
        addressLines = addr2;
    }
    if(data.city || data.state || data.zipcode) {
        addressLines = addressLines + `<br/>${data.city}, ${data.state} ${data.zipcode}`;
    }

    return $(`
        <div>
            <ul class='px-0'>
                <li>Site: ${data.site_name}</li>
                <li>Employee ID: ${data.employee_id}</li>
                <li>${data.email}</li>
                <li>${maybeNull(addressLines)}</li>
                <li>Shoe Type / Size<br/>${maybeNull(data.shoe_type)} / ${maybeNull(data.shoe_size)}</li>
            </ul>
        </div>
    `)[0];
}
