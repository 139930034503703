import $ from "jquery";
import 'jquery-validation';

$(document).ready(function () {

    if ($('.template-login').length === 0) {
        return;
    }

    const validator = $('#forgot-password').validate({
        rules: {
            email: {
                required: true,
                email: true
            },
        },
        messages: {
            email: {
                required: 'Please enter email address',
            },
        }
    });

    //Check if fields are valid and add red border if not
    $('body').on('click', '.btn-forgot-password-next', function () {
        const is_email_valid = validator.element('#inputEmail');

        if (!is_email_valid) {
            $('#inputEmail').addClass('error');
        } else {
            $('#inputEmail').removeClass('error');
        }
    });

    const validator_recover_password = $('#password-reset').validate({
        rules: {
            password: {
                required: true,
                strongPassword: true,
            },
            password_confirm: {
                required: true,
                equalTo: '#inputNewPassword',
            }
        },
        messages: {
            password: {
                required: 'Password required',
            },
            password_confirm: {
                required: '',
                equalTo: 'Passwords do not match'
            }

        }
    });

    //Check if fields are valid and add red border if not
    $('body').on('click', '.btn-recover-password-next', function () {
        const is_password_valid = validator_recover_password.element('#inputNewPassword');
        const is_confirm_valid = validator_recover_password.element('#inputConfirmNewPassword');

        if (!is_password_valid) {
            $('#inputNewPassword').addClass('error');
        } else {
            $('#inputNewPassword').removeClass('error');
        }

        if (!is_confirm_valid) {
            $('#inputConfirmNewPassword').addClass('error');
        } else {
            $('#inputConfirmNewPassword').removeClass('error');
        }
    });

});