import $ from 'jquery';
import Swal from "sweetalert2";

$(document).ready(function() {

    if ($('.template-user-list').length === 0) {
        return;
    }

    const actionForm = document.forms['actionForm'];

    $('.delete-user-button').click(event => {
        const deleteURL = $(event.currentTarget).attr('data-action-url');

        Swal.fire({
            title: 'Delete user?',
            text: 'You will be unable to recover this users account',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: 'red',
        }).then(result => {
            if (result.isConfirmed) {
                // Set the forms submit URL and then submit it
                actionForm.action = deleteURL;
                actionForm.submit();
            }
        });

    });
})