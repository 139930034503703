import {TabulatorFull} from "tabulator-tables";
import $ from "jquery";

import {applyURLQueryParameters, debounce} from "./utils";

export default class RemoteTable extends TabulatorFull {
    constructor(selector, url, columns) {
        super(selector, {
            ajaxURL: url,
            ajaxURLGenerator: (...args) => {return this._generateURL(...args);},
            ajaxResponse: (...args) => {return this._prepareRemoteData(...args);},
            sortMode: 'remote',
            columns: columns,
            placeholder: 'No Records Found'
        });

        this.filterParams = {};
    }

    bindFilterButtons($buttons) {
        $buttons.click(event => {
            event.preventDefault();

            const $el = $(event.currentTarget);
            const filterGroup = $el.attr('data-filter');
            const selectedVal = $el.attr('data-value') || null;

            $el.addClass('active');
            // Mark other buttons as inactive
            $(`.btn-filter[data-filter="${filterGroup}"]`).not($el).removeClass('active');

            if (this.filterParams[filterGroup] !== selectedVal) {
                this.filterParams[filterGroup] = selectedVal;
                this.reload();
            }
        });
    }

    bindSearchInput($searchInput) {
        $searchInput.keyup(debounce(600, () => {
            const term = ($searchInput.val() || '').trim() || null;
            if (term !== this.filterParams.search) {
                this.filterParams.search = term;
                this.reload();
            }
        }));
    }

    reload() {
        this.setData();
    }

    _prepareRemoteData(url, params, resp) {
        return resp;
    }

    _getFilterQueryParams() {
        const queryParams = {};

        for (const [key, val] of Object.entries(this.filterParams)) {
            if (val) {
                queryParams[key] = val;
            }
        }

        return queryParams;
    }

    _generateURL(url, config, tabulatorParams) {
        const queryParams = this._getFilterQueryParams();

        // Sort params
        if (Array.isArray(tabulatorParams.sort) && tabulatorParams.sort.length > 0) {
            let orderBy = [];
            tabulatorParams.sort.map(sortData => {
                let prefix = '';
                if (sortData.dir === 'desc') {
                    prefix = '-';
                }
                orderBy.push(`${prefix}${sortData.field}`);
            });
            queryParams.ordering = orderBy.join(',');
        }

        return applyURLQueryParameters(url, queryParams);
    }
}
