import $ from 'jquery';

$(document).ready(function() {

    if ($('.template-settings').length === 0) {
        return;
    }

    $("#authorization input[name='user_type']").change(function() {
        if ($('input:radio[name=user_type]:checked').val() === "manager") {
            $('#sites').removeClass('d-none');
        } else {
            $('#sites').addClass('d-none');
        }
    }).change();
});
