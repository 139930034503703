import $ from "jquery";
import 'jquery-validation';


$(document).ready(function () {

    // this code applies to the registration page
    if ($('.template-registration').length === 0) {
        return;
    }

    //remove/add visibility eye icon class
    $("#togglePassword, #togglePasswordConfirm").click(function () {
        const passwordInput = $(".password-field");
        const type = passwordInput.attr("type");

        if (type === "password") {
            passwordInput.attr("type", "text");
            $("#togglePassword, #togglePasswordConfirm").removeClass("bi bi-eye-slash").addClass("bi bi-eye");
        } else {
            passwordInput.attr("type", "password");
            $("#togglePassword, #togglePasswordConfirm").removeClass("bi bi-eye").addClass("bi bi-eye-slash");
        }
    });

    // get the timezone from the user's locale
    $("#timezone").val(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const validator = $('#register-form').validate({
        rules: {
            password: {
                required: true,
                strongPassword: true,
            },
            password_confirm: {
                required: true,
                equalTo: '#password',
            },
            terms_agree: {
                required: true,
            }
        },
        messages: {
            password: {
                required: 'Password required',
            },
            password_confirm: {
                required: '',
                equalTo: 'Passwords do not match'
            },
            terms_agree: {
                required: 'Terms must be accepted to continue'
            }
        },
        groups: {
            username: "terms_agree"
        },
        errorPlacement: function (error, element) {
            if (element.attr("name") == "terms_agree") {
                error.insertAfter("label.terms_agree");
            } else {
                error.insertAfter(element);
            }
        }
    });

    //Check if fields are valid and add red border if not
    $('body').on('click', '.btn-next', function () {
        const is_password_valid = validator.element('#password');
        const is_password_confirm_valid = validator.element('#password_confirm');
        const terms_agree_valid = validator.element('#terms_agree');

        if (!is_password_valid) {
            $('#password').addClass('error');
        } else {
            $('#password').removeClass('error');
        }
        if (!is_password_confirm_valid) {
            $('#password_confirm').addClass('error');
        } else {
            $('#password_confirm').removeClass('error');
        }
        if (!terms_agree_valid) {
            $('#terms_agree').addClass('error');
        } else {
            $('#terms_agree').removeClass('error');
        }
    });

});




