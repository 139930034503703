import $ from 'jquery';
import RemoteTable from "../remote_table";

$(document).ready(function() {

    if ($('.template-order-history').length === 0) {
        return;
    }

    const listURL = $('#orderListURL').val();

    function getRowPopupContent(e, cell) {
        const data = cell.getData();
        let employee_code = data.employee_code;
        if(!employee_code) {
            employee_code = '';
        }

        const $popup = $(`
            <div>
                <strong>Employee</strong>
                <div>${data.employee_name}</div>
                <div class="mb-2">${employee_code}</div>
                
                <strong>Address</strong>
                <div class="addr1">${data.address1}</div>
                <div>${data.city}, ${data.state}</div>
                <div class="mb-2">${data.zipcode}</div>
                
                <strong>Shoe Type / Size</strong>
                <div class="mb-2">${data.shoe_type} / ${data.shoe_size}</div>
                
                <strong>Pairs Ordered</strong>
                <div>${data.pairs}</div>
            </div>
        `);
        if (data.address2) {
            $popup.find('.addr1').after(`<div>${data.address2}</div>`);
        }

        return $popup[0];
    }

    const columns = [
        {
            title: 'Created',
            field: 'created_at',
            minWidth: 140,
            resizable: false,
            headerSort: true,
            headerSortStartingDir: 'desc',
            clickPopup: getRowPopupContent
        },
        {
            title: 'Status',
            field: 'status',
            minWidth: 140,
            resizable: false,
            headerSort: true,
            headerSortStartingDir: 'desc',
            clickPopup: getRowPopupContent
        },
        {
            title: 'Employee Name',
            field: 'employee_name',
            minWidth: 140,
            resizable: false,
            headerSort: true,
            headerSortStartingDir: 'desc',
            clickPopup: getRowPopupContent
        },
        {
            title: 'Employee I.D.',
            field: 'employee_code',
            minWidth: 140,
            resizable: false,
            headerSort: true,
            headerSortStartingDir: 'desc',
            clickPopup: getRowPopupContent
        }
    ];

    // Setup the table
    const table = new RemoteTable('#order-table', listURL, columns);

    // Setup search
    table.bindSearchInput($('#searchFilter'));
});
