import $ from "jquery";

const AVAILABLE_COUNTRIES = ['us', 'ca', 'jp'];

export class GooglePlaceDetails {
    constructor(data) {
        this.data = data;

        this.componentByType = {};
        for (const comp of data.address_components) {
            for (const compType of comp.types) {
                this.componentByType[compType] = comp;
            }
        }
    }

    getStreetAddress() {
        let streetAddress = '';

        if (this.componentByType.street_number) {
            streetAddress = this.componentByType.street_number.short_name;
        }

        if(this.componentByType.route) {
            streetAddress = streetAddress + ' ' + this.componentByType.route.short_name;
        }

        return streetAddress.trim();
    }

    getCity() {
        let cityComp;

        if (this.componentByType.locality) {
            cityComp = this.componentByType.locality;

        // Fallback options incase locality is not set (which does happen but is rare)
        } else if (this.componentByType.sublocality) {
            cityComp = this.componentByType.sublocality;
        } else if (this.componentByType.neighborhood) {
            cityComp = this.componentByType.neighborhood;
        }

        return cityComp.long_name;
    }

    getZipCode() {
        return this.componentByType.postal_code.long_name;
    }

    getStateCode() {
        return this.componentByType.administrative_area_level_1.short_name;
    }

    getCountryCode() {
        return this.componentByType.country.short_name.toLowerCase();
    }
}

export function makeAddressAutocomplete(element) {
    const placesAPIOptions = {
        componentRestrictions: { country: AVAILABLE_COUNTRIES },
        fields: ['address_components'],
        types: ['address'],
        strictBounds: false,
        locationBias: 'ipbias'
    };
    return new google.maps.places.Autocomplete(element, placesAPIOptions);
}
